@import "~@/styles/variables";






















































.no-tracking {
  &.card-body,
  .shipment-msg {
    padding-bottom: 0 !important;
  }
}
