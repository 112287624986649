@import "~@/styles/variables";































.med-img {
  height: 55px;
}
