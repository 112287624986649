@import "~@/styles/variables";

























































































































































.change-address-link {
  display: block;
  margin-top: 0.5em;
}
