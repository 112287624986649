@import "~@/styles/variables";







































































































.total-cost-label {
  font-size: $f-size-sm;
}

.total-cost-amount {
  font-size: $f-size-md;
}
