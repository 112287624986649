@import "~@/styles/variables";






























.packet-dose {
  display: inline-flex;
  border-radius: 30px;
  border: 1px solid #ececec;
  padding: 0.2em 1em;
  font-size: 0.8rem;
}

.vertical-bar {
  display: inline-block;
  width: 1px;
  border-left: 1px solid #ececec;
  margin: 0 0.5em;
}
