@import "~@/styles/variables";









































































































.med-img {
  height: 55px;
}

.insurance-pending {
  color: $pp-orange;
}
