@import "~@/styles/variables";
















































































































.insurance-icon {
  height: 40px;
}
