@import "~@/styles/variables";






























.step-edge {
  position: relative;
  overflow: hidden;
  width: 3px;
  background-color: $step-progress-inactive;
  border-radius: 5px;
}

.progress-marker {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform var(--animation-duration, 250ms) linear;
  background-color: $ship-step-progress-active;
}
