@import "~@/styles/variables";

























.pending-insurance {
  background-color: $pp-warning-bg;
}

.title {
  font-size: $f-size-md;
  color: $pp-ocean;
}
