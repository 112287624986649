@import "~@/styles/variables";

























































































.step-progress-bar {
  flex-direction: column;

  .step-container {
    flex-direction: row;
  }
  .progress-section {
    flex-direction: column;
  }
}

.step-container {
  flex-direction: column;
  flex: 1 1 auto;
  transition: opacity var(--animation-duration, 250ms);
  color: $step-progress-inactive;

  &.past,
  &.future {
    .step-content {
      opacity: 0.8;
    }
  }

  &.current,
  &.past {
    color: $ship-step-progress-active;
  }

  &.current {
    &::v-deep .status {
      font-weight: bold;
    }
  }

  &.error {
    color: $danger;
  }

  &:last-child {
    &::v-deep {
      .spacer {
        display: none;
      }
    }
  }
}

.step-content {
  margin-top: 0.2em;
}
