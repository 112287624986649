@import "~@/styles/variables";
































































































/* TODO replace the spinner with loading skeleton */
.orders-details-view .loading-spinner {
  top: 30vh;
}
