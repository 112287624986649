@import "~@/styles/variables";

















@import '~bootstrap/scss/functions';

$node-size: 20px;

.step-node {
  border-radius: 50%;
  width: $node-size;
  height: $node-size;
  margin: 4px;
  line-height: 0;
  transition: all var(--animation-duration, 250ms);
  background-color: $ship-step-progress-active;

  ::v-deep svg circle {
    fill: $pp-primary-blue;
  }
}

.current {
  transform: scale(1.1);
}

.future {
  background: none;
  border: 2px dotted $step-progress-inactive;
}
