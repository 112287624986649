@import "~@/styles/variables";




















.status {
  font-weight: normal;
  margin-bottom: 0.1rem;
}
.sub-text {
  font-size: 0.9em;
}
.spacer {
  height: 1em;
}
